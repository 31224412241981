<template>
  <div class="timeline-wrap" ref="timeline">
    <div class="arrow-tim-wrap">
      <div class="time-and-live">
        <DateRangeSelect
          class="dt-select"
          v-model="dateTime"
          :disabled="permissionV2video === 0"
          :range="false"
          :format="`YYYY-MM-DD HH:mm:ss`"
          @confirm="onDayClick"
          @showDatePanel="getshowDatePanel"
        />
        <!-- 有 live 權限 + 非 live => 才有 跳至直播畫面 按鈕 -->
        <template v-if="permissionV2video > 0">
          <el-tooltip
            popper-class="el-tooltip"
            effect="dark"
            v-delTabIndex
            :disabled="liveMode"
            :visible-arrow="false"
            :content="$t('jump_to_live') /*跳至直播畫面*/"
            placement="top"
          >
            <button v-if="showLiveBtn" class="live-btn" :class="{ live: liveMode }" @click="onSwitchLiveMode">
              <div class="circle"></div>
              <span>LIVE</span>
            </button>
          </el-tooltip>
          <el-tooltip
            popper-class="el-tooltip"
            effect="dark"
            v-delTabIndex
            :visible-arrow="false"
            :content="$t('back_event')/*返回事件*/"
            placement="top"
          >
            <button v-if="showBackEventBtn" class="back-btn" @click="onBackEvent">
              <img src="@/assets/icons/Back.svg" alt="">
            </button>
          </el-tooltip>
        </template>
      </div>
      <div class="hour-div">
        <div
          v-for="(item, index) in axisRangeList"
          :key="index"
          @click="hourClick(item.value)"
          class="hour-btn"
          :class="{ selected: item.value === axisRangeValue }"
        >{{item.label}}</div>
      </div>
    </div>
    <svg class="timeaxis-svg">
      <g class="data-g" />
      <g class="timeaxis-g" />
      <g class="event-data-g" /> 
    </svg>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import * as d3 from 'd3'
import moment from 'moment'
import { formatTime } from '@/utils/lib.js'

// 一律以中心點縮放 研究中
// 日期套件尚未加上去
export default {
  name: 'TimeAxis',
  components: {
    DateRangeSelect: () => import('../Base/DateRangeSelect.vue'),
  },
  props: {
    liveList: Array,
    videoList: Array,
    videoIndex: Number,
    liveMode: Boolean,
    videoCurrentSeconds: Number,
    reTimeAxis: Boolean,
    permissionV2video: Number,
    axisRangeList: {
      type: Array,
      default: () => [
        { label: '24H', value: 12 },
        { label: '8H', value: 4 },
        { label: '1H', value: 0.5 },
        { label: '10M', value: 0.08333333 },
      ]
    },
    axisRange: {
      type: Number,
      default: 4
    },
    showLiveBtn: {
      type: Boolean,
      default: false
    },
    showBackEventBtn: {
      type: Boolean,
      default: false
    },
    showEventRect: {
      type: Boolean,
      default: false
    },
    eventData: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      svgWidth: null,
      SVG: null,
      xDomain: null,
      zDomain: [
        new Date().getTime(),
        new Date().getTime() + 2 * 60 * 60 * 1000
      ],
      axisTopMargin: 64,
      pointerTim: null,
      initialWidth: null,
      liveStartTime: new Date(),
      manualMoveTim: false, // 按箭頭 或 拖動時間軸 改動時間 => 要停止目前影片 並找對應時間的影片播放
      //updateRectListTimeout: null // 直播進行中 videoList每一分鐘更新一次 讓畫面的歷史影片長方形可以保持在最新狀態
      dateTime: new Date(),
      timeFormat: '%H:%M',
      timeMinute: 30, // 時間間隔 30分鐘
      drawData: [], // 濃縮起迄時間 縮短化區塊時間
      eventRectData: [], // 事件區塊資料
      eventFillColor: '#FFC600',
      dragTimeout: null,
      minPointerTim: null, // 紀錄影片區塊最小時間
      maxPointerTim: null, // 紀錄影片區塊最大時間
      TimByArrow: false,
      showDatePanel: false,
    }
  },
  computed: {
    ...mapState(['LeftMenuOpen', 'RightMenuOpen', 'TopMenuHeightLevel']),
    ...mapGetters(['timezone']),
    lang() {
      const navLang = navigator.language
      let language = 'en'
      if (navLang === 'zh-TW') language = 'zh-tw'
      if (navLang === 'en-US' || navLang === 'en') language = 'en'
      return language
    },
    x: {
      get() {
        if (this.xDomain) {
          return d3
            .scaleTime()
            .domain(this.xDomain)
            .range([0, this.initialWidth])
        }
        let startTim = new Date().getTime() // 3.5
        return (
          d3
            .scaleTime()
            // 要以當下時間為正中央
            .domain([
              new Date(startTim).addHours(Math.abs(this.axisRangeValue) * -1),
              new Date(startTim).addHours(Math.abs(this.axisRangeValue))
            ])
            .range([0, this.initialWidth])
        )
      },
      set(val) {
        this.xDomain = val
      }
    },
    zoomX: {
      // 更動寬度時 要用目前的寬度 還有zoom後的時間區間 重新產生xScale 再渲染時間軸
      // 要放在computed裡面的變數 預設要有值 不然就會偵測到空或null的預設值然後報錯
      get() {
        return (
          d3
            .scaleTime()
            .domain(this.zDomain)
            .range([0, this.svgWidth])
        )
      },
      set(val) {
        this.zDomain = val
      }
    },
    axisRangeValue: {
      get() {
        return this.axisRange
      },
      set(val) {
        this.$emit('update:axisRange', val)
      }
    },
    videoLiveMode() {
      if (this.videoList?.length === 0 || this.videoIndex < 0) return false
      // 現在時間點 > video.startTime + video.currentTime => live 的 vod
      const userId = this.videoList[this.videoIndex].user.id
      const liveDev = this.liveList.find((live) => live.id === userId)

      if (!this.liveMode && liveDev) return true
      return false
    },
  },
  watch: {
    liveMode() {
      // 直播用當下時間 歷史影片用當下影片時間 去比對差異 並移動時間軸
      this.setAxisTime()
      // if (this.liveMode) {
      //   // 直播進行中 videoList每一分鐘更新一次 讓畫面的歷史影片長方形可以保持在最新狀態
      //   this.updateVideoListWhenLive()
      // } else {
      //   if (this.updateRectListTimeout) clearTimeout(this.updateRectListTimeout)
      //   this.updateRectListTimeout = null
      // }
      if (this.liveMode) {
        this.liveStartTime = new Date()
      }
    },
    videoCurrentSeconds(sec) {
      // ＊＊＊＊＊＊＊＊＊ 這裡會跳回當下時間 ＊＊＊＊＊＊＊＊＊＊
      if (this.videoCurrentSeconds == 0) {
        this.liveStartTime = new Date()
      }

      // 開啟日曆時 不更新時間
      if (!this.showDatePanel) {
        if (this.liveMode) {
          this.dateTime = new Date()
        } else if (!this.liveMode) {
          // 現在時間點 > video.startTime + video.currentTime => live 的 vod
          const v = this.videoList[this.videoIndex]
          if (v) {
            const currentTimeMs = new Date(v.startTime).getTime() + sec*1000
            this.dateTime = new Date(currentTimeMs)
          }
        }
      }

      if (this.manualMoveTim) return
      this.setAxisTime()
    },
    pointerTim() {
      // 有變動就emit時間出去 重load影片進度
      if (this.pointerTim > new Date()) {
        // 超出現在時間 就直接加一天 加一天這個func有判斷：
        // 加了一天後超過當下時間就維持在當下時間 所以直接拿來複用
        this.setDate('add', 'day')
      }
      this.showPointerTim()
      this.$emit('loadVideoWithPointerTim', this.pointerTim) // 畫GPS軌跡
      this.onLivedraw() // 濃縮畫法 縮短拖拉時間
      if (this.showEventRect) this.drawEventRect() // 畫事件區塊
      // console.log(this.pointerTim)
      // this.$set(this.attributes[0], 'dates', moment(this.pointerTim).format('YYYY-MM-DD'))
    },
    LeftMenuOpen() {
      this.changeSvgWidth()
    },
    RightMenuOpen() {
      this.changeSvgWidth()
    },
    TopMenuHeightLevel() {
      this.changeSvgWidth()
    },
    zoomX() {
      // zoomX在拖動、滾時間軸 還有按箭頭更改時間時會重新assign
      // 每重新assign 就要重抓中心時間 重畫day interval line&text
      // 然後重新渲染時間軸
      this.setPointerTim()
      if (this.TimByArrow) {
        this.$emit('stopAndPlayAtSpecificTim', this.pointerTim)
        this.TimByArrow = false
      }
      if (!this.reTimeAxis || this.drawData.length == 0) {
        this.drawDataRect() // 畫底色、影片區塊與事件區塊
      }
      this.setPointerPosition() // 畫時間軸中心時間黃三角
      // update axes with these new boundaries
      this.drawAxis() // 畫時間刻度
    },
    axisRangeValue() {
      this.rescaleTimeAxis()
      this.drawDataRect()
    },
    'videoList.length'() {
      this.drawDataRect()
    },
  },
  mounted() {
    this.addMethodInDate()
    this.dateTime = new Date()
    let min = moment(this.dateTime).tz(this.timezone).format('mm')
    this.dateTime.setMinutes(min)

    // 設定繪製事件區塊的顏色
    if (this.showEventRect && (this.eventData.type === 'chased' || this.eventData.type === 'sos')) 
      this.eventFillColor = '#F94144'
    
    this.setTimeAxisFormat()
    this.SVG = d3.select('.timeaxis-svg')
    this.initialWidth = this.$refs.timeline.clientWidth
    this.zoomX = this.x.domain() // 只有在mounted時一樣 zoom或改變寬度後 zoomX和x就不一樣了
    this.changeSvgWidth()
    window.addEventListener('resize', this.changeSvgWidth)
    // 拖動時間軸和改變時間軸寬度 會重新渲染時間軸
    // 時間軸每次重新渲染 都會重新取得 左邊跟右邊的時間 和 時間軸寬度
    this.setZoom()
    this.SVG.call(this.zoom).on('wheel.zoom', null).on('dblclick.zoom', null)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.changeSvgWidth)
  },
  methods: {
    addMethodInDate() {
      // 在Date新增方法 方便增加天數 畫path和text要用
      Date.prototype.addDays = function (days) {
        this.setDate(this.getDate() + days)
        return this
      }

      Date.prototype.addSecs = function (secs) {
        this.setTime(this.getTime() + secs * 1000)
        return this
      }

      Date.prototype.addMins = function (mins) {
        this.setTime(this.getTime() + mins * 60 * 1000)
        return this
      }

      Date.prototype.addHours = function (hours) {
        this.setTime(this.getTime() + hours * 60 * 60 * 1000)
        return this
      }
    },
    removeTimeAxis() {
      this.drawData = []
      d3.selectAll('.videoLine').remove()
    },
    rescaleTimeAxis() {
      this.setTimeAxisFormat()
    
      this.zDomain= [
        new Date(this.pointerTim).getTime() - (this.axisRangeValue * 60 * 60 * 1000),
        new Date(this.pointerTim).getTime() + (this.axisRangeValue * 60 * 60 * 1000)
      ]
      this.xDomain = this.zDomain
      this.x = this.zDomain
      this.zoomX = this.zDomain
      // 切換解析度 要重設 zoom
      this.SVG.call(this.zoom.transform, d3.zoomIdentity).on('wheel.zoom', null).on('dblclick.zoom', null)
    },
    setTimeAxisFormat() {
      switch (this.axisRangeValue) {
        case 0.04166667:
          this.timeFormat = '%H:%M'
          this.timeMinute = 1
          break
        case 0.08333333:
          this.timeFormat = '%H:%M'
          this.timeMinute = 1
          break
        case 0.5:
          this.timeFormat = '%H:%M'
          this.timeMinute = 5
          break
        case 1:
          this.timeFormat = '%H:%M'
          this.timeMinute = 30
          break
        case 12:
          this.timeFormat = '%H'
          this.timeMinute = 60
          break
        default:
          this.timeFormat = '%H:%M'
          this.timeMinute = 30
          break
      }
    },
    hourClick(val) {
      if (this.axisRangeValue == val) return
      this.$emit('update:axisRange', val)
    },
    showPointerTim() {
      // 更新時間選擇器的時間，若有開啟日曆則不更新
      if (!this.showDatePanel)
        this.dateTime = new Date(this.pointerTim)
    },
    stopAndPlayAtSpecificTim() {
      //this.manualMoveTim = true
      this.TimByArrow = true
      //this.$emit('stopAndPlayAtSpecificTim', this.pointerTim)
    },
    getTimeDelta() {
      // 計算時間差 帶入setAxisTime改變時間軸domain
      // 直播模式 用現在時間 歷史模式 用即將播放的歷史影片計算 和pointerTim的時間差
      let tim
      if (this.liveMode) {
        tim = this.liveStartTime
      } else {
        if (this.videoIndex != -1) {
          tim = new Date(
            // 把時間轉換成UI畫面呈現的時間
            this.utcToTaipeiTime(this.videoList[this.videoIndex].startTime)
          )
        } else tim = new Date(this.pointerTim)
      }
      if (this.videoCurrentSeconds) {
        tim = new Date(tim).addSecs(this.videoCurrentSeconds)
      }
      let minDiff = moment(tim).diff(this.pointerTim) / 1000 / 60
      return minDiff
    },
    setAxisTime(timDelta = null) {
      // 移動後 更改this.x domain 下次zoom才會知道this.x位移過了
      // 改變zoomX 就會重新渲染一次時間軸的UI
      if (!timDelta) timDelta = this.getTimeDelta()

      let [orgStart, orgEnd] = this.x.domain() // 為了移動this.x的原點
      let [zoomS, zoomE] = this.zoomX.domain() // 為了讓畫面套用最新的效果
      this.x = [
        new Date(orgStart).addMins(timDelta),
        new Date(orgEnd).addMins(timDelta)
      ]
      this.zoomX = [
        new Date(zoomS).addMins(timDelta),
        new Date(zoomE).addMins(timDelta)
      ]
    },
    setDate(typ, interval) {
      let delta = typ == 'add' ? 1 : -1
      let min = interval == 'day' ? 24 * 60 : 1
      let minDiff = delta * min
      // 最晚只能到當下時間
      if (new Date(this.pointerTim).addMins(minDiff) > new Date()) {
        minDiff = moment(new Date()).diff(this.pointerTim) / 1000 / 60
      }
      this.setAxisTime(minDiff)
    },
    utcToTaipeiTime(tim) {
      // 把時間轉換成UI畫面呈現的時間
      // 時間用moment.utc()轉換就是utc時間;若用moment()轉換就會是當地時區的時間
      // 根據時區轉換
      return formatTime(tim)
    },
    drawWhiteBg() {
      d3.select('.bg').remove()
      // 畫白色透明底條
      let h = 10
      d3.select('.data-g')
        .append('rect')
        .attr('class', 'bg')
        .attr('width', this.svgWidth)
        .attr('height', h)
        .attr('fill', '#ffffff6b')
        .attr('stroke', 'none')
        .attr('x', 0)
        .attr('y', this.axisTopMargin - h)
    },
    onLivedraw() {
      const processedData = []
      this.drawData.forEach(item => {
        // 停止時間大於現在時間的話 就不畫
        const start = this.zoomX(new Date(item.startTime))
        const e = this.zoomX(new Date(item.stopTime))
        const end = (e > this.zoomX(new Date())) ? this.zoomX(new Date()) : e
        let width = end - start
        if (!width || width < 0) width = 0
        processedData.push({
          start,
          end,
          width
        })
      })
      if (this.liveMode) { // 直播模式 要從直播開始時間補畫到當下時間
        const s = this.zoomX(this.liveStartTime)
        // 避免當下時間超過中軸 畫到直播時間
        const e = this.zoomX(new Date(this.pointerTim))
        let width = e - s
        if (!width || width < 0) width = 0
        processedData.push({
          start: s,
          end: e,
          width
        })
      }

      d3.select('.data-g')
        .selectAll('.videoLine')
        .data(processedData)
        .join('rect')
        .attr('class', 'videoLine')
        .attr('width', (d) => d.width)
        .attr('height', 10)
        .attr('fill', '#fff')
        .attr('stroke', 'none')
        .attr('x', (d) => d.start)
        .attr('y', this.axisTopMargin - 10)
    },
    drawVideoRect() {
      if (!this.videoList.length) {
        this.removeTimeAxis()
        return
      } 
      let drawList = this.videoList.filter((item) => item)
      let olds = '' //因為是倒排序 所以判別 startTime
      let olde = ''
      this.drawData = []
      drawList.forEach((item) => {
        if (!item.stopTime) item.stopTime = new Date().toISOString()
        if (item.startTime < this.minPointerTim) this.minPointerTim = item.startTime
        if (item.stopTime > this.maxPointerTim) this.maxPointerTim = item.stopTime
        if (!olds && !olde) {
          olds = item.startTime
          olde = item.stopTime
          this.minPointerTim = item.startTime
          this.maxPointerTim = item.stopTime
        } else {
          if (item.stopTime == olds) olds = item.startTime
          else {
            this.drawData.push({startTime: olds, stopTime: olde})
            olds = item.startTime
            olde = item.stopTime
          }
        }
      })
      this.drawData.push({startTime: olds, stopTime: olde})
      //控制不要一直重劃 時間軸
      this.$emit('setReTimeAxis', true)
      this.onLivedraw() // 濃縮畫法 縮短拖拉時間
    },
    getEventRectData() {
      // 畫事件區塊
      this.eventRectData = []
      if (this.eventData && Object.keys(this.eventData).length > 0) {
        let start = this.zoomX(this.eventData.startTime)
        let end = this.zoomX(this.eventData.stopTime)
        let width = end - start
        if (!width || width < 0) width = 0
        this.eventRectData.push({
          start,
          end,
          width
        })
      }
    },
    drawEventRect() {
      this.getEventRectData()

      d3.select('.event-data-g')
        .selectAll('.eventRect')
        .data(this.eventRectData)
        .join('rect')
        .attr('class', 'eventRect')
        .attr('width', (d) => d.width)
        .attr('height', 10)
        .attr('fill', this.eventFillColor)
        .attr('stroke', 'none')
        .attr('x', (d) => d.start)
        .attr('y', this.axisTopMargin - 10)
    },
    drawDataRect() {
      this.drawWhiteBg() // 畫白色透明底條
      this.drawVideoRect() // 畫白色實心底條 顯示歷史影片所在位置
      if (this.showEventRect) this.drawEventRect() // 畫事件區塊
    },
    setPointerTim() {
      this.pointerTim = this.zoomX.invert(this.svgWidth / 2)
    },
    // 畫時間軸中心時間黃色三角
    setPointerPosition() {
      let w = this.svgWidth / 2
      let h = this.axisTopMargin
      d3.select('.pointer').remove()
      d3.select('.timeaxis-svg')
        .append('polygon')
        .attr('points', `${w - 7},${h - 22} ${w},${h - 10} ${w + 7},${h - 22}`)
        .attr('stroke', '#FFC600')
        .attr('fill', '#FFC600')
        .attr('class', 'pointer')
    },
    changeSvgWidth() {
      // window resize或是開關左右bar都要重新調整時間軸寬度
      this.svgWidth = this.$refs.timeline.clientWidth
      this.drawDataRect() // 畫底色、影片區塊與事件區塊
    },
    xAxis(x) {
      return d3
        .axisBottom(x)
        .tickFormat((tim) => {
          return d3.timeFormat(this.timeFormat)(tim)
        })
        .ticks(d3.timeMinute.every(this.timeMinute))
    },
    drawAxis() {
      this.gxAxis = d3
        .select('.timeaxis-g')
        .attr('font-size', '12px')
        .attr('line-height', '18px')
        .attr('color', '#fff')
        .attr('transform', 'translate(0,' + this.axisTopMargin + ')')
        .call(this.xAxis(this.zoomX))
    },
    setZoom() {
      this.zoom = d3.zoom().
        extent([[0,0],[300,300]]).
        on("start", this.dragstarted).
        on('zoom', this.dragged).
        on("end", this.dragended)
    },
    // 開始拖拉時間軸
    dragstarted() {
      //console.log(this.permissionV2video) 無權限不可拖拉 
      if (this.permissionV2video > 0) {
        if(this.dragTimeout) { clearTimeout(this.dragTimeout) }
        this.manualMoveTim = true
        //console.log(e.transform.x)
      }
    },
    // 時間軸拖拉中
    dragged(e) {
      if (this.permissionV2video > 0) {
        let newX = e.transform.rescaleX(this.x)
        this.zoomX = newX.domain()
      }
    },
    // 時間軸拖拉結束
    dragended() {
      if (this.permissionV2video > 0) {
        if(this.dragTimeout) { clearTimeout(this.dragTimeout) }
        this.dragTimeout = setTimeout(() => {
          this.$emit('stopAndPlayAtSpecificTim', this.pointerTim)
          this.$emit('loadVideoWithPointerTim', this.pointerTim)
        }, 400)
      }
    },
    onDayClick(dateTime) {
      // console.log(`[onDayClick] dateTime:`, dateTime)
      this.dateTime = dateTime
      if (this.dateTime) {
        // console.log(this.dateTime)
        let newDateTime = formatTime(this.dateTime)
        // 移動時間軸到指定時間
        const minDiff = moment(new Date(newDateTime)).diff(this.pointerTim) / 1000 / 60
        this.setAxisTime(minDiff)
        this.$emit('stopAndPlayAtSpecificTim', new Date(newDateTime))
      }
    },
    onSwitchLiveMode() {
      if (this.liveMode) return
      this.$emit('switchLiveMode')
    },
    getshowDatePanel(show) {
      this.showDatePanel = show
      if (this.showDatePanel) {
        this.$emit('videoPause')
      } else {
        this.$emit('videoPlay')
      }
    },
    onBackEvent() {
      this.$emit('backEvent')
    }
  },
}
</script>

<style lang="scss" scoped>
.timeline-wrap {
  width: 100%;
  background-color: $color_39425D;
  display: flex;
  flex-direction: column;
  height: px2rem(90);
  box-sizing: border-box;
  position: relative;
}

.arrow-tim-wrap {
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  color: $color_FFF;
  top: px2rem(6);
  left: 0;
  width: 100%;
}
.time-and-live {
  display: flex;
  column-gap: 4px;
  // background-color: #f00;
}

.hide {
  display: none;
}

.timeaxis-svg {
  box-sizing: border-box;
  width: 100%;
  height: px2rem(90);
  cursor: grab;
}

.hour-div {
  display: flex;
  position: absolute;
  top: 0px;
  right: 10px;
}

.hour-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 32px;
  outline: none;
  border: none;
  color: #ffffff33 ;
  background: #ffffff1a;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background: #ffffff33;
  }

  &:first-child {
    border-radius: 6px 0 0 6px;
  }

  &:last-child {
    border-radius: 0 6px 6px 0;
  }

  &.selected {
    color: #ffffff;
    background: #ffffff4d;
  }
}

.dt-select:deep {
  .overlay {
    color: $color_FFF;
    background-color: #ffffff1a;
    @include font_style(px2rem(16), px2rem(32), $color_FFF, 700);
    &:hover {
      background-color: #ffffff33;
    }
  }
}

.live-btn {
  display: flex;
  height: px2rem(32);
  border-radius: 6px;
  background: #ffffff1a;
  font-size: px2rem(16);
  padding: 0 px2rem(12);
  cursor: pointer;

  .circle {
    width: px2rem(8);
    height: px2rem(8);
    border-radius: 50%;
    background-color: #9D9D9D;
    margin-right: px2rem(12);
  }

  &:hover {
    background: #ffffff33;
  }

  &.disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }

  &.live {
    background: transparent;
    cursor: default;
    .circle {
      background: $color_4C68EB;
    }
  }
}

.back-btn {
  display: flex;
  align-items: center;
  height: px2rem(32);
  border-radius: 6px;
  background: #ffffff1a;
  padding: 0 px2rem(8);
  cursor: pointer;

  &:hover {
    background: #ffffff33;
  }

  &.disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }
}

// .live-btn {
//   // position: relative;
//   // top: px2rem(-2);
//   // left: px2rem(300 + 4);
//   display: flex;
//   // margin-left: 0.5rem;
//   border-radius: 0.375rem; // 6px
//   padding: 0rem 0.5rem;
//   font-size: 1rem;
  
//   // background-color: $color_6E7D93_30;
//   outline: 1px solid yellowgreen;
//   li {
//     width: 1rem;
//     color: $color_9D9D9D;
//     &.live {
//       color: $color_4C68EB;
//     }
//   }

//   .vod {
//     border-radius: 0.375rem; // 6px
//     padding: 0rem 0.5rem;
//     background-color: $color_6E7D93_30;
//     transition: background-color 0.3s ease-in-out;
//   }
//   .live-vod {
//     border-radius: 0.375rem; // 6px
//     padding: 0rem 0.5rem;
//     background-color: $color_6E7D93_30;
//     &:hover {
//       cursor: pointer;
//       background-color: $color_6E7D93_50;
//     }
//   }

// }
</style>
